import * as React from "react"
import { graphql } from "gatsby"
import ComparisonScripts from "../components/ComparisonWidget";

// import { Bluetooth as Memory, Storage, Trigger } from 'grommet-icons';
import {
  Card,
  CardBody,
  CardFooter,
  Text,
  Button
} from 'grommet';

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  const options = {
    serviceType: 'broadband',
    comparisonStyle: 'quick',
    // comparisonStyle: 'autopilot',
    comparisonFilters: {
      // hideAll: true,
      // suppliers: [ supplier._id ]
    },
    comparisonTable: {
      showLoadMore: false,
      showCallout: false,
      showHeader: false,
      showFAQs: false,
    },
    // settings: {
      // amplitudeId: 'a124ab5ff51d89b62de0755d385a82f1'
    // }
  };

  return (
    <Layout location={location} title={siteTitle}>
      <div className="hero-main">
        <div className="hero-main-inner">
          <h1 className="hero-heading">Broadband Scanner</h1>
          <p className="hero-subheading">Always be on the fastest, cheapest, most reliable broadband</p>
          <a href="#widget"><Button primary label="Get Started" /></a>
        </div>
        <div className="hero-ocean">
          <div className="hero-wave"></div>
          <div className="hero-wave"></div>
        </div>
      </div>
      {/* <div>
        <div className="global-wrapper">
          Supported Suppliers: EE, BT, TalkTalk, Cuckoo & Many More
        </div>
      </div> */}
      <div id="widget">
        <ComparisonScripts options={options} />
        <div id="homeboxComparison"></div>
      </div>
      {/* <div>
        TrustPilot
      </div> */}
      {/* <div className="global-wrapper">
        <h2>How It Works</h2>
        <Card>
          <CardBody pad="medium">100% Free</CardBody>
          <CardFooter pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Text size="xsmall">Get Started Now</Text>
          </CardFooter>
        </Card>
        <Card>
          <CardBody pad="medium">100% Free</CardBody>
          <CardFooter pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Text size="xsmall">Get Started Now</Text>
          </CardFooter>
        </Card>
        <Card>
          <CardBody pad="medium">100% Free</CardBody>
          <CardFooter pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Text size="xsmall">Get Started Now</Text>
          </CardFooter>
        </Card>
      </div> */}
      {/* <div>
        FAQs
      </div> */}
      {/* <div>
        CTA
      </div> */}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
